import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/contact.module.scss';
import { useMPEvent } from '@util/mixpanel';

const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.data.value === state.selectProps.value ? '#e5e5ff' : '#fff',
    fontWeight:
      state.data.value === state.selectProps.value ? 'bold' : 'normal',
    color: '#5b3892',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#e5e5ff',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#5b3892',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#5b3892',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    fontWeight: 900,
    color: '#5b3892',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  valueContainer: (provided) => ({
    ...provided,
    '@media (max-width: 768px)': {
      'min-height': '64px',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    '&:hover': {
      cursor: 'pointer',
    },
    lineHeight: 1.56,
    '@media (max-width: 768px)': {
      'min-height': '64px',
    },
    '@media (min-width: 768px)': {
      fontSize: '18px',
      padding: '0.5rem 0 0.5rem 2rem',
    },
    borderRadius: '8px',
    border: 'solid 1px #ab96d3',
    color: '#5b3892',
    fontWeight: 500,
    backgroundColor:
      state.selectProps.selectedValue === 'feedback' ? '#e5e5ff' : '#fff',
  }),
};

export default function Contact({ data }) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    subTitle,
    options,
    selectLabel,
    textareaLabel,
    thankYouMessage,
    submitButtonCopy,
  } = data.contentfulContactPage;

  const [selected, select] = useState(options[0].value);
  const [loading, toggleLoading] = useState(false);
  const [feedbackReceived, toggleFeedback] = useState(false);
  const { trackPageLoad } = useMPEvent();

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/contact`,
      url_name: 'contact',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const renderOption = options.find((option) => option.value === selected);
  const availableOptions =
    process.env.GATSBY_ZENDESK_WIDGET === 'true'
      ? options.filter((option) => option.value !== 'feedback')
      : options;

  async function handleSubmit(event) {
    event.preventDefault();
    toggleLoading(true);
    const myForm = document.getElementById(event.target.id);
    const formData = new FormData(myForm);
    const body = new URLSearchParams(formData).toString();
    await fetch(`/${nodeLocale}/contact`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    });
    toggleFeedback(true);
    toggleLoading(false);
  }

  return (
    <PageLayout node_locale={nodeLocale} pageTitle={pageTitle}>
      <PageHero data={{ header, subTitle }} />

      <div className={styles.background}>
        <Container>
          <Row className="mb-5 w-100">
            <Col md={12} lg={{ offset: 1, span: 10 }}>
              <Select
                aria-label={selectLabel}
                name="feedback-toggle"
                placeholder={renderOption.select}
                defaultValue={selected}
                value={selected}
                selectedValue={selected}
                onChange={(op) => select(op.value)}
                options={availableOptions.map((o) => ({
                  value: o.value,
                  label: o.select,
                }))}
                styles={selectStyles}
                isSearchable={false}
              />
            </Col>
          </Row>
          <Row className="mb-4 w-100">
            <Col md={12} lg={{ offset: 1, span: 10 }}>
              <div
                className={styles[renderOption.style]}
                dangerouslySetInnerHTML={{
                  __html: renderOption.content.childMarkdownRemark.html,
                }}
              />
              {/* eslint-disable no-nested-ternary */}
              <div
                className={
                  renderOption.style === 'feedback' && !feedbackReceived
                    ? ''
                    : 'd-none'
                }
              >
                <form
                  data-netlify="true"
                  name="contact-feedback"
                  id="feedback-form"
                  netlify
                  onSubmit={handleSubmit}
                  className={styles.feedbackForm}
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact-feedback"
                  ></input>
                  <textarea
                    aria-label={textareaLabel}
                    rows={4}
                    className={styles.textarea}
                    name="feedback"
                  />
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="secondary"
                    className={styles.submitButton}
                  >
                    {submitButtonCopy}
                  </Button>
                </form>
              </div>
              <div
                className={
                  renderOption.style === 'feedback' && feedbackReceived
                    ? ''
                    : 'd-none'
                }
                dangerouslySetInnerHTML={{
                  __html: thankYouMessage.childMarkdownRemark.html,
                }}
              />
              {/* eslint-enable no-nested-ternary */}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulContactPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      subTitle
      options {
        select
        style
        value
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      selectLabel
      textareaLabel
      thankYouMessage {
        childMarkdownRemark {
          html
        }
      }
      submitButtonCopy
    }
  }
`;
